(function($) {
    $.fn.jTranspose = function () {
        return this.each(function () {
            let $table = $(this),
                $tableCopy = $('<table>').addClass('mobile-clone'),
                $rows = $table.find('tr'),
                nRows = $rows.length,
                nColumns = $rows.first().children().length;

            for (let c = 0; c < nColumns; c++) {
                for (let r = 0; r < nRows; r++) {
                  let $row = $('<tr>');
                  let $r = $rows.eq(r),
                  $cell = $r.children().eq(c).clone();

                  $row.append($cell);
                  $tableCopy.append($row);
                }
            }
            $table.after($tableCopy);
            galleryModal();
        });
    };
}(jQuery));
