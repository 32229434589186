// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import "flowbite/dist/flowbite.turbo.js";
import "flowbite/dist/datepicker.turbo.js";
import "alpine-turbo-drive-adapter";
import Alpine from "alpinejs";
import ApexCharts from "apexcharts";
import "./add_jquery"
import { Popover } from 'flowbite';
import { registerCursorTracker, DefaultCreditCardDelimiter, formatCreditCard, getCreditCardType, formatDate } from 'cleave-zen';
import "./vendor/resonsive-table.js";

//
const popover_options = {
  placement: 'bottom',
  triggerType: 'hover',
  offset: 10,
  // onHide: () => {
  //   console.log('popover is shown');
  // },
  // onShow: () => {
  //   console.log('popover is hidden');
  // },
  // onToggle: () => {
  //   console.log('popover is toggled');
  // },
};

const popoverItems = document.querySelectorAll(".popover-button");

popoverItems.forEach((item) => {
  const $targetEl  = document.getElementById(item.dataset.popoverTarget);
  const $triggerEl = document.getElementById(item.id);

  new Popover($targetEl, $triggerEl, popover_options);
});

function bookingForm() {

  if($("#btn-next").length > 0) {
    var leftPos = 0;
    var _width     = $('#scroll-dates').innerWidth();
    var full_width = $('#scroll-dates')[0].scrollWidth;
  }

  $("#btn-next").click(function(e) {
    if(full_width <= leftPos+_width) {
      $('#user_payment_process_appointment_date_time').val('');
      $('#user_payment_process_staff_id').val('');
      $('#user_payment_process_start_date').val($(this).data('start-date'));
      $('#appointment_data').html($('#loading_interface').html());
    } else {
      e.preventDefault()
      leftPos  += _width;
      $('#scroll-dates').scrollLeft(leftPos);
    }
  });

  $("#btn-prev").click(function(e) {
    if ($(this).attr('href') === "#") {
      e.preventDefault();
    } else {
      if (leftPos == 0) {
        $('#user_payment_process_appointment_date_time').val('');
        $('#user_payment_process_staff_id').val('');
        $('#user_payment_process_start_date').val($(this).data('start-date'));
        $('#appointment_data').html($('#loading_interface').html());
      } else {
        e.preventDefault();
        leftPos  -= _width;
        $('#scroll-dates').scrollLeft(leftPos);
      }
    }
  });

  $('.render-date').click(function(e) {
    e.preventDefault();
    $('.datepicker').removeClass('active');
    $('.timepicker').removeClass('active');
    $(this).find('.datepicker').addClass('active');
    var selector = $(this).data('dateSelector');
    $('#available_times .avaiable_times').addClass('hidden');
    $('#avaiable_times_'+selector).removeClass('hidden');

    if($("#confirm-schedule").length) {
    } else {
      $('#user_payment_process_staff_id').val('');
      $('#user_payment_process_appointment_date_time').val('');
      $('#user_payment_process_appointment_avaiable_id').val('');
    }
  });

  $('.select-time').click(function(e) {
    e.preventDefault();
    $('.timepicker').removeClass('active');
    $(this).find('.timepicker').addClass('active');

    if($("#confirm-schedule").length) {
      $("#confirm-schedule").attr('href',$(this).data('href')).removeClass('hidden');

      $("#selected-date-dl").css('display', 'flex');
      $("#selected-time-dl").css('display', 'flex');

      $("#render-selected-date").text($(this).data('date'));
      $("#render-selected-time").text($(this).data('time'))
    } else{
      $('#user_payment_process_staff_id').val($(this).data('staff-id'));
      $('#user_payment_process_appointment_date_time').val($(this).data('date-time'));
      $('#user_payment_process_appointment_avaiable_id').val($(this).data('id'));
    }
  });


  // toggle more Infos for Kind
  $(".toggle-online-description").click(function(e){
    e.preventDefault();
    const _id = $(this).data('id');
    $("#online-description-"+_id).toggleClass("hidden");
  });
}


function galleryModal() {
  // modal
  const targetEl = document.getElementById('modal-image');

  const options = {
    placement: 'bottom-right',
    backdrop: 'dynamic',
    backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
    closable: true,
    onHide: () => {
      document.getElementById('modal-image-url').innerHTML = '';
    },
    onShow: () => {
      //console.log('modal is shown');
    },
    onToggle: () => {
      //console.log('modal has been toggled');
    },
  };
  const instanceOptions = {
    id: 'modal-image',
    override: true
  };

  //const modal = new Modal($modalElement, modalOptions);

  const gallery_items = document.querySelectorAll(".open-modal");
  gallery_items.forEach((item) => {
    item.addEventListener("click", function(e){
      const img_url = item.dataset.url;
      const modal   = new Modal(targetEl, options, instanceOptions);
      const imageEl = document.getElementById('modal-image-url');
      imageEl.innerHTML = '<img src="'+img_url+'" loading="lazy" />';
      modal.show();
      document.getElementById('modal-image-close').addEventListener("click", function(e){
        modal.hide();
      });
    });
  });



}

function mobileNumberForm() {
  const targetElement   = document.getElementById('dropdown-phone-button-span');
  const country_buttons = document.querySelectorAll('.country-select');
  country_buttons.forEach((item) => {
    item.addEventListener("click", function(e){
      targetElement.innerHTML = item.dataset.countryCode;
      document.getElementById('dropdown-phone-button').click(); // hide
      if(document.getElementById('user_mobile_country_code')) {
        document.getElementById('user_mobile_country_code').value = item.dataset.countryCodeValue;
      } else {
        document.getElementById('user_payment_process_mobile_country_code').value = item.dataset.countryCodeValue;
      }
    });
  });

  const country_buttons_modal = document.querySelectorAll('.country-select-modal');
  country_buttons_modal.forEach((item) => {
    item.addEventListener("click", function(e){
      e.preventDefault();
      targetElement.innerHTML = item.dataset.countryCode;
      if(document.getElementById('user_mobile_country_code')) {
        document.getElementById('user_mobile_country_code').value = item.dataset.countryCodeValue;
      } else {
        document.getElementById('user_payment_process_mobile_country_code').value = item.dataset.countryCodeValue;
      }
    });
  });
}
function checkoutForm() {
  // document.getElementById('user_cc_cvv').addEventListener('input', e => {
  //   e.target.value = formatDate(e.target.value, {
  //     datePattern: ['m', 'y'],
  //   });
  // });
  document.getElementById('user_cc_expiration').addEventListener('input', e => {
    e.target.value = formatDate(e.target.value, {
      datePattern: ['m', 'y'],
    });
  });
  document.getElementById('user_cc_number').addEventListener('input', e => {
    const value = e.target.value;
    const options  = { delimiter: " " };
    e.target.value = formatCreditCard(value, options);
    const creditCardType = getCreditCardType(value);

    const visa_image = document.getElementById('card-kind-visa');
    const mastercard_image = document.getElementById('card-kind-mastercard');

    if (creditCardType == "visa") {
      visa_image.classList.remove("filter");
      visa_image.classList.remove("grayscale");
      mastercard_image.classList.add("filter");
      mastercard_image.classList.add("grayscale");
    } else if(creditCardType == "mastercard") {
      mastercard_image.classList.remove("filter");
      mastercard_image.classList.remove("grayscale");
      visa_image.classList.add("filter");
      visa_image.classList.add("grayscale");

    } else {
      mastercard_image.classList.add("filter");
      mastercard_image.classList.add("grayscale");
      visa_image.classList.add("filter");
      visa_image.classList.add("grayscale");
    }
  });
}

function renderLongevityScore() {

  var score   = document.getElementById('longevity-score').getAttribute('data-value');
      score   = parseFloat(score);
  if ( score > 100.0) {
    var score1 = 100.0;
    var score2 = score - 100.0;
    score2     = (score2 / 20.0) * 100.0;
  } else {
    var score1 = score;
    var score2 = 0.0
  }


  score  = score.toFixed(2);
  score1 = score1.toFixed(2);
  score2 = score2.toFixed(2);

  var score_label = "";

  if (window.currentLocale === 'de') {
    var score_label = "Dein Score";
  } else if (window.currentLocale === 'en') {
    var score_label = "Your Score";
  }

  var options = {
    series: score > 100.0 ? [score1, score2] : [score1],
    chart: {
      height: 350,
      type: 'radialBar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          show: true,
          total: {
            show: true,
            label: score_label,
            formatter: function (w) {
              return score
            }
          },
          value: {
            formatter: function(val) {
              return score;
            },
            color: '#111',
            fontSize: '36px',
            show: true,
          }
        }
      }
    },
    stroke: {
      lineCap: 'round'
    },
    labels: score > 100.0 ? [score_label, score_label] : [score_label],
  };

  var chart = new ApexCharts(document.querySelector("#longevity-score"), options);

  chart.render();

  $('.toggle-score-values').click(function(e) {
    //e.preventDefault();
    var key = $(this).data('key');
    $(this).find('svg').toggleClass('hidden');
    $('.tr-score-details-'+key).toggleClass('hidden');
  });

  // load anchor
  if($('#longevity-score-table').length > 0) {
    var hash = $(location).attr('hash');
    if (hash.length > 0) {
      $(hash).find('a').click();
    }
  }
}

window.renderLongevityScore = renderLongevityScore;
window.checkoutForm = checkoutForm;
window.mobileNumberForm = mobileNumberForm;
window.bookingForm = bookingForm;
window.galleryModal = galleryModal;


document.addEventListener("alpine:init", () => {
  if (document.querySelector('#modal-image')) {
    galleryModal();
  }
  if (document.querySelector('#longevity-score')) {
    renderLongevityScore();
  }
  if (document.querySelector('#user_cc_card_needed')) {
    checkoutForm();
  }
  if (document.querySelector('#dropdown-phone')) {
    mobileNumberForm();
  }
  if (document.querySelector('#service_kinds_overview') || document.querySelector('#available_times')) {
    bookingForm();
  }
});

$('#user_payment_process_marketing_how_find_us').change(function() {
  if($(this).val() == "Anderes" || $(this).val() == "other") {
    $('#marketing_how_find_us').removeClass('hidden');
  } else {
    $('#marketing_how_find_us').addClass('hidden');
    $('#marketing_how_find_us').find('input').val('');
  }
})
$(function() {
  if($('.render_html_content').length > 0) {
    $('.render_html_content table').each(function(i, obj) {
      $(this).jTranspose();
    });
  }

  var clicked = false;
  $('#remove_cookie').click(function() {
    if(clicked === false) {
      if (window.location.href.includes("/de/")) {
        window.location = "/de/users/remove_two_factor_cookie";
      } else {
        window.location = "/en/users/remove_two_factor_cookie";
      }
      clicked = true;
    }
  });
})

window.Alpine = Alpine;
Alpine.start();
import "./channels"
